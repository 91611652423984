import React from "react";
import Header from "../../components/Header";

const PrivacyPolicy = ({navigate}) => {
  return (
    <>
      <Header navigate={navigate} />
      <div className="max-w-[960px] px-4 lg:px-0 mx-auto mb-24 pt-32">
        <h1 className="text-black text-3xl lg:text-5xl font-semibold pb-12 lg:pb-16 text-center">
          Privacy Policy
        </h1>
        <hr className="" />
        <div>
          <p className="pt-16">This Privacy Policy describes:</p>
          <ul className="list-disc pl-6">
            <li>
              Information that we receive from you when you interact with the
              Services
            </li>
            <li> How we use and process the information that we receive</li>
            <li>
              If and why Personal Information may be disclosed to third parties
            </li>
            <li>
              Your choices regarding the collection and processing of your
              Personal Information
            </li>
          </ul>
          <p>
            Please note that this Privacy Policy does not apply to information
            collected through third-party websites or services that you may
            access through the Services PLEASE REVIEW THIS PRIVACY POLICY
            CAREFULLY. When you submit information to or through the Services,
            you consent to the collection and processing of your information as
            described in this Privacy Policy. By using the Services, you accept
            the terms of this Privacy Policy and consent to our collection, use,
            disclosure and retention of your information as described in this
            Privacy Policy.
          </p>
          <b>
            IF YOU DO NOT AGREE WITH ANY PART OF THIS PRIVACY POLICY OR OUR
            TERMS OF SERVICE, PLEASE DO NOT USE ANY OF THE SERVICES.
          </b>
          <p>
            The Effective Date of this Privacy Policy is set forth at the top of
            this Policy. Whenever possible, we will provide you with advance
            written notice of our changes to this Privacy Policy. We will not
            make retroactive changes that reduce your privacy rights unless we
            are legally required to do so. Your continued use of the Services
            after the Effective Date constitutes your acceptance of the amended
            Privacy Policy. The amended Privacy Policy supersedes all previous
            versions.
          </p>
          <h2 className="my-8 font-bold">INFORMATION WE COLLECT</h2>

          <h5>Types of Information</h5>
          <p>
            We collect various kinds of information that you provide to us as
            well as information we obtain from your use of the Services. Some of
            the types of information that we collect include: “Personal
            Information”—information associated with or used to identify or
            contact a specific person. Personal Information includes: (1)
            contact data (such as e-mail address, telephone number and
            employer); (2) demographic data (such as gender, date of birth and
            zip code); [and] (3) certain Usage Data (defined below), such as IP
            address[.] [; and (4) describe any other categories of information
            the user provides or the digital service collects, such as
            user-generated content or purchases]. Certain Personal Information,
            such as information about personal health or finances, is
            characterized as Sensitive and subject to stricter regulation than
            other personal information. Before providing it to use, we urge you
            to carefully consider whether to disclose your Sensitive Personal
            Information to us. If you do provide Sensitive Personal Information
            to us, you consent to its use and disclosure for the purposes and in
            the manner described in this Privacy Policy. “Usage
            Data”—information about an individual's online activity that, by
            itself, does not identify the
          </p>
          <ul className="list-disc pl-6">
            <li>
              technical information, including your browser type, service
              provider, IP address, operating system and webpages visited;
            </li>
            <li>
              information about what you've searched for and looked at while
              using the Services;
            </li>
            <li>
              metadata, which means information related to items you made
              available through the Services, such as the date, time or location
              that a shared photograph or video was taken or posted.
            </li>
          </ul>
          <p>
            (Generally, we do not consider Usage Data as Personal Information
            because Usage Data by itself usually does not identify an
            individual. Personal Information and Usage Data may be linked
            together. Different types of Usage Information also may be linked
            together and, once linked, may identify an individual person. Some
            Usage Data may be Personal Information under applicable law.)
            “Location Data” is a category of Personal Information collected
            about the location of a mobile device or computer, including:
          </p>
          <ul className="list-disc pl-6">
            <li>
              the location of the mobile device or computer used to access the
              Services derived from GPS or WiFi use;
            </li>
            <li>
              the IP address of the mobile device or computer or internet
              service used to access the Services,
            </li>
            <li>
              other information made available by a user or others that
              indicates the current or prior location of the user, such as
              [information that you or others post indicating your location]
              [geotag information in photographs].
            </li>
          </ul>
          <h5>How We Collect Information</h5>
          <p>
            Whether we collect certain types of information and how we process
            it depends on how you use and access the Services. Some information
            is collected automatically through use of cookies and similar data
            collection tools. We collect information about you in the following
            ways: From You. We collect information from you when you:
          </p>

          <ul className="list-disc pl-6">
            <li className="mt-3">
              <b>Use the Services.</b> We collect Personal Information from you
              when you create an account to use one of the Services, contact us
              for help or information or otherwise voluntarily provide your
              Personal Information. We collect the information and content that
              you submit to us, [such as when you enter comments or submit a
              review].
            </li>
            <li className="mt-3">
              <b>Connect with social media though the Services.</b> The Services
              may offer you the ability to use Facebook Connect or other social
              media services (collectively, “social media”) in conjunction with
              certain Services. When you access the Services through your
              Facebook or other Social Media account, the Services may,
              depending on your privacy settings, have access to information
              that you have provided to the Social Media platform. We may use
              this information for the purposes described in this Privacy Policy
              or at the time the information was collected.
            </li>
          </ul>
          <p className="mt-3">
            <b>From Our Business Partners and Service Providers.</b> Third
            parties that assist us with our business operations also collect
            information (including Personal Information and Usage Data) about
            you through the Services and share it with us. For example, our
            vendors collect and share information with us to help us detect and
            prevent fraud. We may combine the information we collect from you
            with information from other sources and use the combined information
            as described in this Privacy Policy.
            <br />
            <br />
            <b>Usage Data.</b> We also automatically collect Usage Data when you
            interact with the Services.
            <br />
            <br />
            <b>From cookies and other data collection tools..</b> We, along with
            the service providers that help us provide the Services, use small
            text files called “cookies”, which are small computer files sent to
            or accessed from your web browser or your computer's or tablet's
            hard drive that contain information about your computer, such as a
            user ID, user settings, browsing history and activities conducted
            while using the Services. Cookies are not themselves personally
            identifiable, but may be linked to Personal Information that you
            provide to us through your interaction with the Services. A cookie
            typically contains the name of the domain (internet location) from
            which the cookie originated, the “lifetime” of the cookie (i.e.,
            when it expires) and a randomly generated unique number or similar
            identifier. Cookies help us improve the Services by [Include all
            that apply: tracking users' navigation habits and storing users'
            password, customizing users' experience with the Services; enabling
            us to analyze technical and navigational information about the
            Services; and helping to detect and prevent fraud]. We also use
            other cookies and other data collection tools (such as web beacons
            and server logs), which we collectively refer to as “data collection
            tools,” to help improve your experience with the Services. [For
            example, data collection tools help us remember users and make the
            Services more relevant to them]. The Services also may use data
            collection tools to collect information from the device used to
            access the Services, such as operating system type, browser type,
            domain and other system settings, as well as the operating system
            used and the country and time zone in which the computer or device
            is located. Web browsers allow some control of most cookies through
            the browser settings. To find out more about cookies, including how
            to manage and delete cookies, visit www.allaboutcookies.org. Some
            web browsers (including some mobile web browsers) provide settings
            that allow a user to reject cookies or to alert a user when a cookie
            is placed on the user's computer, tablet or mobile device. Most
            mobile devices also offer settings to reject mobile device
            identifiers. Although users are not required to accept cookies or
            mobile device identifiers, blocking or rejecting them may prevent
            access to some features available through the Services.
          </p>

          <h5>How We Use Your Information</h5>
          <p>
            We may use the information we collect for any of the following
            purposes [include all that apply and add any additional uses, as
            applicable]:
          </p>
          <ul className="list-disc pl-6">
            <li>to provide the Services to you;</li>
            <li>
              to operate, improve and personalize the products and services we
              offer, and to give each user a more consistent and personalized
              experience when interacting with us;
            </li>
            <li>
              for customer service, security, to detect fraud or illegal
              activities, or for archival and backup purposes in connection with
              the provision of the Services
            </li>
            <li>
              to communicate with users [list examples of types of
              communications];
            </li>
            <li>
              to better understand how users access and use the Services, for
              the purposes of trying to improve the Services and to respond to
              user preferences, including language and location customization,
              personalized help and instructions, or other responses to users'
              usage of the Services;
            </li>
            <li>
              to help us develop our new products and services and improve our
              existing products and services;
            </li>
            <li>
              to provide users with advertising and direct marketing that is
              more relevant to you;
            </li>
            <li>
              to enforce our [Terms of Service] or other applicable policies;
              and
            </li>
            <li>
              to assess the effectiveness of and improve advertising and other
              marketing and promotional activities on or in connection with the
              Services.
            </li>
            <p className="mt-3">
              Please note that, in order to provide you with a better experience
              and to improve the Services, information collected through the
              Services may [, subject to user privacy controls (if available),]
              be used in an aggregated or individualized manner. For example,
              personal information collected during use of one of the services
              may be used to suggest particular content that can be made
              available to the user on another of the Services or be used to try
              to present more relevant advertising in another of the Services.
            </p>
          </ul>

          <h5>How We Share and Disclose Your Information</h5>
          <p>
            We may share and disclose information as described at the time
            information is collected or as follows:
          </p>
          <p className="mt-3">
            <b>When you consent.</b>We may share Personal Information with third
            parties if you have given us your consent to do so. We will only
            share Sensitive Personal Information when you have given us your
            opt-in consent.
          </p>
          <p className="mt-3">
            <b>To perform services.</b>We may disclose Personal Information to
            third parties in order to perform services requested or functions
            initiated by users, such as [__]. In addition, we may disclose
            Personal Information in order to identify a user in connection with
            communications sent through the Services. We also may offer users
            the opportunity to share information with friends and other users
            through the Services.
          </p>
          <p className="mt-3">
            <b>
              With third party service providers performing services on our
              behalf.
            </b>
            We share information, including Personal Information, with our
            service providers to perform the functions for which we engage them
            (such as hosting and data analyses). We may share information as
            needed to operate other related services.
          </p>
          <p className="mt-3">
            <b>For legal purposes.</b>We also may share information that we
            collect from users, as needed, to enforce our rights, protect our
            property or protect the rights, property or safety of others, or as
            needed to support external auditing, compliance and corporate
            governance functions. We will disclose Personal Information as we
            deem necessary to respond to a subpoena, regulation, binding order
            of a data protection agency, legal process, governmental request or
            other legal or regulatory process. We may also share Personal
            Information as required to pursue available remedies or limit
            damages we may sustain.
          </p>
          <p className="mt-3">
            <b>In aggregated form. </b>We may share Personal Information about
            you in an aggregated form—that is, in a statistical or summary form
            that does not include any personal identifiers—with third parties in
            order to discover and reveal trends about how users like you
            interact with our services.
          </p>
          <p className="mt-3">
            <b>During corporate changes.</b>We may transfer information,
            including your Personal Information, in connection with a merger,
            sale, acquisition or other change of ownership or control by or of
            us or any affiliated company (in each case whether in whole or in
            part). When one of these events occurs, we will use reasonable
            efforts to notify users before your information is transferred or
            becomes subject to a different privacy policy. Comment: The FTC has
            maintained that the acquiring company, as well as the target
            company, may be held directly responsible for failing to abide by
            the target company's privacy policies. Even though the acquiring
            company did not itself make any promises to the target company's
            users—and thus its use of the data could not be characterized as
            “deceptive”—the FTC has asserted that the receipt or use of data in
            violation of the target's privacy policies could be “unfair” under{" "}
            <a
              href="https://www.bloomberglaw.com/product/privacy/citation/38%20stat.%20717"
              target="_blank"
              rel="noreferrer"
            >
              Section 5 of the FTC Act
            </a>
            .
          </p>

          <h5>Information Storage and Security</h5>
          <p>
            We retain information as long as it is necessary and relevant for
            our operations. We also retain Personal Information [OPTION: from
            closed accounts] to comply with applicable law, prevent fraud,
            resolve disputes, troubleshoot problems, assist with any
            investigation, [enforce our Terms of Service], [collect any fees
            owed] and other actions permitted by law. After it is no longer
            necessary for us to retain information, we dispose of it according
            to our data retention and deletion policies. We employ
            industry-standard security measures designed to protect the security
            of all information submitted through the Services. However, the
            security of information transmitted through the internet can never
            be guaranteed. We are not responsible for any interception or
            interruption of any communications through the internet or for
            changes to or losses of data. Users of the Services are responsible
            for maintaining the security of any password, user ID or other form
            of authentication involved in obtaining access to password protected
            or secure areas of any of our digital services. In order to protect
            you and your data, we may suspend your use of any of the Services,
            without notice, pending an investigation, if any breach of security
            is suspected. Access to and use of password protected and/or secure
            areas of any of the Services are restricted to authorized users
            only. Unauthorized access to such areas is prohibited and may lead
            to criminal prosecution. Comment: Avoid mentioning specific security
            protocols that may change over time. Work with IT security to craft
            an accurate statement that neither creates unintended commitments
            nor limits the data security options going forward. Statements made
            about security applied to personal information collected through a
            digital service must be accurate and carefully crafted. This sample
            provision contains minimal representations that can later be used
            offensively by a user. The FTC has taken numerous enforcement
            actions based on allegations of insufficient data security against
            businesses that experience data breaches. Companies are vulnerable
            to data breaches no matter what their policies say, so take care not
            to provide the user—or the FTC—with too great a sense of comfort by
            overstating commitments about data security. This provision is not a
            place for marketing—remind users that no security measures are
            perfect and sharing information on the web is always fraught with
            risks.
          </p>

          <h5>Your Rights</h5>
          <p>
            It is important to us that you are able to access and review the
            Personal Information we have about you and make corrections to it or
            delete it, as necessary. You can visit [your account] to manage and
            correct the information we have on file about you. If you have any
            questions about how to access your Personal Information, please
            contact us at [____].
          </p>

          <h5>Links to Third Party Services</h5>
          <p>
            The Services may contain links to third-party websites and services
            (“Third Party Services”) with which we have no affiliation. A link
            to any Third Party Service does not mean that we endorse it or the
            quality or accuracy of information presented on it. If you decide to
            visit a Third Party Service, you are subject to its privacy policy
            and practices and not this Privacy Policy. We encourage you to
            carefully review the legal and privacy notices of all other digital
            services that you visit.
          </p>

          <h5>Disclaimer: Not Child-Directed</h5>
          <p>
            The Services are not intended for use by children. If you are under
            the age of majority in your place of residence, you may use the
            Services only with the consent of or under the supervision of your
            parent or legal guardian. Comment: This affirmation should only be
            included when a digital service provider is not directed at children
            and has no actual knowledge that it collects information from
            children. Consistent with the requirements of the{" "}
            <a
              href="https://www.bloomberglaw.com/product/privacy/citation/15%20usc%206501"
              target="_blank"
              rel="noreferrer"
            >
              Children's Online Privacy Protection Act
            </a>{" "}
            (COPPA), if we learn that we have received any information directly
            from a child under age 13 without first receiving his or her
            parent's verified consent, we will use that information only to
            respond directly to that child (or his or her parent or legal
            guardian) to inform the child that he or she cannot use the Services
            and subsequently we will delete that information.
          </p>

          <h5>U.S./EU/Swiss Data Privacy Framework Certification</h5>
          <p>
            We comply with the EU-U.S. Data Privacy Framework (DPF) and the
            Swiss-U.S. Data Privacy Framework (DPF), as set forth by the U.S.
            Department of Commerce, regarding the collection, use, and retention
            of personal information from European Union member countries and
            Switzerland. We have certified that we adhere to the DPF Principles
            of notice, choice, accountability for onward transfer, security,
            data integrity and purpose limitation, access, and recourse,
            enforcement, and liability. To learn more about the DPF program, and
            to view our certification, please visit{" "}
            <a
              href="https://www.dataprivacyframework.gov/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.dataprivacyframework.gov
            </a>
            . Any personal information collected about EU visitors through the
            Services is processed in the United States by us or by a party
            acting on our behalf. When you provide personal information to us
            through the Services, you consent to the processing of your data in
            the United States. The Services are hosted in the United States.
            Comment: This provision notifies the digital service user that any
            of the user's personal information collected through the Services is
            transferred to the U.S. for processing and/or storage. By using the
            Services, the user consents to the processing of this personal
            information and consents to the transfer of the data to the U.S.
            With respect to the personal information of EU residents, data
            transfers can lawfully be made only with the unambiguous, explicit
            and specific consent of the individual. This provision provides
            evidence of that consent. The privacy policy should bold or format
            the consent language so that it is obvious and evident to the user.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
