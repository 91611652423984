import React from "react";
import logo from "../../logo.svg";

const Header = ({
  activeSection,
  homeRef,
  aboutRef,
  recruitingRef,
  complianceRef,
  performanceRef,
  payrollRef,
  navigate,
}) => {
  const scrollToSection = (sectionRef, path) => {
    navigate(`/${path}`);
    sectionRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  // const onPressLogin = () => {
  //   window.open("https://dev.aelkimi.com/signin", "_blank");
  // }

  return (
    <header className="bg-[#5479F7] px-5 lg:px-10 min-h-16 items-center flex fixed w-full">
      <img src={logo} alt="logo" className="w-28 lg:w-36" />
      <ul className="gap-6 ml-auto text-white items-center hidden md:flex">
        <li
          className={`cursor-pointer hover:text-[#39FF14] ${
            activeSection === "home" ? "text-[#39FF14]" : ""
          }`}
          onClick={() => scrollToSection(homeRef, "home")}
        >
          Home
        </li>
        <li
          className={`cursor-pointer hover:text-[#39FF14] ${
            activeSection === "about" ? "text-[#39FF14]" : ""
          }`}
          onClick={() => scrollToSection(aboutRef, "about")}
        >
          About
        </li>
        <li
          className={`cursor-pointer hover:text-[#39FF14] ${
            activeSection === "recruiting" ? "text-[#39FF14]" : ""
          }`}
          onClick={() => scrollToSection(recruitingRef, "management")}
        >
          Time Management
        </li>
        <li
          className={`cursor-pointer hover:text-[#39FF14] ${
            activeSection === "compliance" ? "text-[#39FF14]" : ""
          }`}
          onClick={() => scrollToSection(complianceRef, "compliance")}
        >
          Compliance
        </li>
        <li
          className={`cursor-pointer hover:text-[#39FF14] ${
            activeSection === "performance" ? "text-[#39FF14]" : ""
          }`}
          onClick={() => scrollToSection(performanceRef, "performance")}
        >
          Performance
        </li>
        <li
          className={`cursor-pointer hover:text-[#39FF14] ${
            activeSection === "payroll" ? "text-[#39FF14]" : ""
          }`}
          onClick={() => scrollToSection(payrollRef, "payroll")}
        >
          Payroll
        </li>
        {/* <li className="border border-solid border-white rounded-full px-4 py-2 cursor-pointer" onClick={onPressLogin}>
          Login
        </li> */}
      </ul>
    </header>
  );
};

export default Header;
