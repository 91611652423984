/* eslint-disable no-useless-escape */
import { useState } from "react";
import guyHeaderImg from "../../assets/images/guy-header.webp";
import { toast } from "react-toastify";

const Hero = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const isValidEmail = (email) => {
    const regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(email);
  };

  const handleOnChangeEmail = (value) => {
    setEmail(value);
    if (emailError) {
      setEmailError("");
    }
  };

  const handleOnBlurEmail = () => {
    if (!isValidEmail(email)) {
      setEmailError("Enter an email address like example@mysite.com.");
    }
  };

  const onSubmitEmail = async () => {
    if (!isValidEmail(email)) {
      setEmailError("Enter an email address like example@mysite.com.");
      return;
    }

    try {
      await fetch('https://dev-api.aelkimi.com/landing/email', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      setEmail('');
      setEmailError('');
      toast.success("Email submitted successfully!");
    } catch (err) {
      toast.error("Failed to submit email. Please try again.");
      console.error(err);
    }
  };

  return (
    <section className="bg-[#5479F7] flex w-full flex-col lg:flex-row justify-between px-5 lg:px-11 pt-20">
      <div className="text-white lg:w-1/2 flex flex-col justify-center items-center lg:items-start">
        <h1 className="text-6xl lg:text-[84px] leading-tight font-bold mb-7">
          HR meets IPA: <br className="hidden lg:flex" /> one byte at a{" "}
          <br className="hidden lg:flex" />
          time.
        </h1>
        <div className="flex flex-col lg:max-w-96 lg:p-6 gap-3 w-full">
          <label>Company Email*</label>

          <input
            type="text"
            className="bg-transparent border-2 border-solid border-white rounded h-11 px-3 py-2 outline-none hover:border-black"
            value={email}
            onChange={(e) => handleOnChangeEmail(e?.target?.value)}
            onBlur={handleOnBlurEmail}
          />
          {emailError && <p className="text-red-500">{emailError}</p>}
          <button
            className="bg-[#7DF9FF] text-[#5479F7] rounded h-11 font-semibold"
            onClick={onSubmitEmail}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="lg:w-1/2 mt-4">
        <img
          loading="lazy"
          src={guyHeaderImg}
          alt="mobile"
          className="w-full h-full object-cover max-h"
        />
      </div>
    </section>
  );
};

export default Hero;
