import React from "react";
import Header from "../../components/Header";

const TermsOfService = ({ navigate }) => {
  return (
    <>
      <Header navigate={navigate} />
      <div className="max-w-[960px] px-4 lg:px-0 mx-auto mb-24 pt-32">
        <h1 className="text-black text-3xl lg:text-5xl font-semibold pb-12 lg:pb-16 text-center">
          Terms of Service
        </h1>
        <hr className="" />
        <div>
          <p className="pt-16">
            These Terms of Service (“Terms of Service” or “Terms”) are effective
            immediately for users accessing or using the service by registering
            for a new account or without an account using the websites,
            networks, mobile applications, or other services provided. [For
            those with pre-existing accounts, these Terms of Service will become
            effective on ______] These Terms of Service govern access to and use
            of [COMPANY NAME] products and services, including those offered
            through [COMPANY NAME] websites, networks, mobile applications, or
            other services provided.
          </p>
          <p className="font-semibold">
            PLEASE NOTE: These terms include a binding arbitration provision and
            class action waiver. By agreeing to these Terms of Service, you
            agree that all claims against Company will be resolved by binding,
            individual arbitration and waive your right to bring or participate
            in any class, group, or representative action or proceeding except
            as stated below.
          </p>
          <h5>I. DEFINITIONS:</h5>

          <div>
            <p className="py-2">A. Parties.</p>
            <ul className="list-decimal pl-10">
              <li> “You” and “your” refer to the user of the Service.</li>
              <li>
                “We,” “us,” and “our” and “Company” refer to [COMPANY NAME] [and
                its affiliates and subsidiaries].
              </li>
            </ul>
            <p className="py-2">
              B. “Service” refers to Company's website ([URL]) and mobile
              applications.
            </p>
            <p className="py-2">
              “Account” refers to your credentials and associated rights to
              access and utilize the Service.
            </p>
            <p className="py-2">
              “Content” refers to text, images, photos, audio, video, branding
              (including trade names, trademarks, service marks, or logos),
              interactive features, software, metrics, and all other forms of
              data or communication whether provided by you, Company, or a third
              party.
            </p>

            <h5>II. CHANGES TO THESE TERMS OF SERVICE</h5>
            <p>
              Company may revise and update these Terms of Service without
              notice, including adding and deleting terms. All changes are
              effective immediately upon posting on Company's website. If you do
              not agree to the revised terms, your recourse is to [stop using
              the service/close your account]. Continued use of the Service
              following a change to the Terms of Service indicates your
              acknowledgement and your agreement to be bound by the revised
              Terms of Service.
            </p>

            <h5>
              III. MODIFICATION, SUSPENSION, OR TERMINATION OF THE SERVICE
            </h5>
            <p>
              Company reserves the right to modify, suspend, or terminate,
              temporarily or permanently, the Service or any feature or
              component of the service, in whole or part, to any individual,
              group, or everyone, for any reason at Company's sole discretion.
            </p>

            <h5>IV. GOVERNING LAW</h5>
            <p>
              These terms of service, and all claims arising out or relating to
              these terms, will be governed by the law of [STATE], without
              regard to its conflicts of law principles.
            </p>

            <h5>V. ELIGIBILITY</h5>
            <p>
              A. You may not use the Service if Company has previously suspended
              or removed you from the Service. B. By using the Service, you
              represent: a) If you are an individual, that you are at least 18
              years of age; b) If you are company or organization, that the
              person creating the account has the authority to act on behalf of
              that entity, and that such entity accepts these terms; c) You have
              not been previously suspended or removed from the Service; and d)
              Your registration and your use of the Service complies with all
              applicable laws and regulations.
            </p>

            <h5>VI. PRIVACY</h5>
            <p>
              Our privacy policy can be found at{" "}
              <a href="/privacy-policy" className="underline text-blue-700">
                privacy policy.
              </a>
            </p>

            <h5>VII. ACCOUNTS</h5>
            <p>
              A. Except for portions of the Service which Company allows users
              to access without registration, you must establish an Account with
              Company to use the Service. <br />
              B. You agree to provide accurate, current, and complete
              information about yourself and to use the account management tools
              to keep such information accurate, current, and complete. <br />
              C. You also agree to keep your password confidential. You should
              not reuse your account password for the Service for other
              applications or services. You also acknowledge that your account
              belongs only to you and agree not to provide any person other than
              you (if an individual) or not acting as your agent (if a company
              or organization) with access to this Service or portions of it
              using your user name, password or other security information. You
              are responsible for maintaining the security of your account and
              password, and you are responsible for all actions taken with your
              account. <br />
              D. You may deactivate your User Account and end your registration
              at any time, for any reason, by [PROCESS]. <br />
              E. Company may suspend or terminate your Account for any reason at
              any time.
            </p>

            <h5>VIII. SERVICES</h5>

            <p>
              A. Use of the Service Subject to strict compliance with these
              Terms of Service, Company grants you a non-exclusive, limited,
              revocable, personal, non-transferable license to use the Service[,
              and to download and use any mobile application on your mobile
              device in object code form,] for your use. All rights not
              expressly granted are reserved by Company [and its licensors and
              other third parties]. <br />
              <br />
              B. Communications by the Service By accessing or using the
              Service, you consent to accept and receive communications from
              Company through the Service or by other means such as e-mail, push
              notifications, text messages (including SMS and MMS), and phone
              calls at the addresses you provide to the Company. These
              communications may promote Company [or customers or affiliates of
              Company] and may be initiated by Company[, customers, affiliates,
              and/or other users of the Service]. You further understand that
              communications may be sent using an automatic telephone dialing
              system, and that you may be charged by your carrier for certain
              communications such as SMS messages or phone calls. <br />
              <br />
              C. Description of the Service <br />
              <br />
              D. Prohibited Activities You agree that you will not, and you will
              not attempt to: <br />
              a) Copy, display, distribute, download, license, modify, publish,
              re-post, reproduce, reuse, sell, transmit, or use the Service
              and/or information provided therein to create a derivative work;{" "}
              <br />
              b) Decompile, disassemble, and/or reverse engineer any portion of
              the Service, including any included third-party content or
              services; <br />
              c) Impersonate any person or entity, or otherwise misrepresent
              your affiliation with a person or entity; <br />
              d) Distribute viruses or other harmful computer code through the
              Service or through any third-party content or service; <br />
              e) Using the Service to send or facilitate the sending of mass
              unsolicited data packets, SMS texts, e-mails, or other traffic,
              such as distributed denial of service (DDoS) or “spam”
              communication efforts; <br />
              f) Use or access for the benefit of an entity subject to economic
              sanctions in the United States[, and other appropriate
              jurisdictions]; <br />
              g) Use or grant access that would violate the import or export
              laws of the United States[, and other appropriate jurisdictions];{" "}
              <br />
              h) Use the Service to violate any local, state, or national law;{" "}
              <br />
              i) Use the Service in any way that harms Company, its service
              providers, suppliers, affiliates, or any other user of the
              Service; <br />
              j) Harvest any information from the Service; <br />
              k) Take any action that, at Company's sole discretion, is deemed
              to impose an unreasonable or disproportionately large load on
              Company's infrastructure or otherwise make excessive traffic
              demands of the Service; COMMENT: The ability to enforce a
              prohibition against scraping varies by jurisdiction and by type of
              service. Some terms of service have separate prohibitions against
              service provider data and user data scraping. <br />
              l) Conduct automated queries or other automated activity with the
              purpose of obtaining information from the Service; <br />
              m) Attempt to gain unauthorized access to the Service, user
              accounts, computer systems, or networks connected to the Service;{" "}
              <br />
              n) Use any device, software or routine that interferes with the
              proper working of the Service, or otherwise attempt to interfere
              with the proper working of the Service; <br />
              o) Deny access or use of the service to any user other than
              yourself;
              <br />
              p) Commit, incite, or threaten an act of violence through the
              Service; <br />
              q) Defame another through the Service; <br />
              r) Violate the privacy of another through the Service; <br />
              s) Engage in conduct that Company, at its discretion, finds
              vulgar, obscene, or offensive; <br />
              t) Otherwise use the Service in any manner that exceeds the scope
              of use granted above.
            </p>

            <h5>IX. FEES AND PAYMENT</h5>
            <p>
              A. You agree to pay all fees or charges incurred by your Account
              in accordance with the fees, charges, and billing terms in effect
              at the time a fee or charge is due and payable.
              <br />
              <br />
              B. By providing the Company with your credit card number and
              associated payment information, you agree that we are authorized
              to immediately charge the payment account you provide for all fees
              and charges due and payable to the Company hereunder and that no
              additional notice or consent is required.
              <br />
              <br />
              C. We reserve the right to modify or implement a new pricing
              structure at any time prior to billing you for your initial
              payment or for future payments due pursuant to these Terms of
              Service.
            </p>

            <h5>X. THIRD PARTY CONTENT AND WEBSITES</h5>
            <p>
              A. Company's Service may provide access to outside content.
              Additionally, the Service may include third-party applications,
              products, or services. Company may also offer Service through
              other third-party services. <br />
              <br />
              B. Your use of third-party content and services, whether on the
              Service or elsewhere, is subject to all terms, conditions,
              practices, and policies set forth by those services and are at
              your own risk. Company makes no representations or warranties with
              respect to third-party applications, products, or services, or how
              they operate, including operations including the privacy,
              security, exchange, and transmission of your data. Company does
              not endorse nor holds responsibility for information, practices,
              and policies of third-party content and services. <br />
              <br />
              C. As Company is distinct from any third-party content or services
              with whom Company partners for the Service, or to which Company
              otherwise links to, you acknowledge and agree that Company is not
              responsible for the quality, integrity, safety, accuracy,
              availability, reliability, or legality of such third-party
              websites, or resources contained therein, and that Company is not
              responsible or liable for any content, advertising, products, or
              other material available from such third-party content or
              services. Further, you acknowledge that Company shall not be
              responsible or liable, directly or indirectly, for any damage,
              harm, injury, or loss of any kind caused or alleged to be caused
              by or in connection with the use of or reliance upon any content,
              material, or services available through any third-party content or
              services.
            </p>

            <h5>XI. DISPUTE RESOLUTION</h5>
            <p>
              A. Disputes <br />
              <br />
              1. You and Company recognize that a bona fide dispute as to any
              matters arising out of or relating to these terms (including their
              validity, amendment, waiver or termination, and the consequences
              thereof) (a “Dispute”) may from time to time arise. <br />
              <br />
              2. In the event of the occurrence of such a Dispute, you or we
              may, by notice to the other party by [METHOD], have such Dispute
              referred for attempted resolution by good faith negotiations
              between you and our representative [by phone or videoconference]
              to be completed within [thirty (30)] days after such notice is
              received (“Negotiation Period”). The Negotiation Period may be
              extended only by written consent of the parties. <br />
              <br />
              B. Binding Arbitration <br />
              <br />
              1. In the event that an agreement is not reached during the
              Negotiation Period, you or we agree that any Dispute [except for
              those excepted in B.3 below,] shall be adjudicated exclusively by
              binding arbitration. You or we may initiate such arbitration by
              written notice; you may serve us by mail at [ADDRESS], and we may
              serve you by e-mail at the e-mail address you provided to us as
              part of your account registration.
              <br />
              <br />
              2. General Arbitration Procedures
              <br />
              a. Unless the Parties agree on a different set of arbitration
              rules, the arbitration shall be governed by the Federal
              Arbitration Act, 9 U.S.C. 1 et seq., and conducted in accordance
              with the [APPLICABLE ARBITRATION BODY'S RULES]. <br />
              b. If an in-person hearing is to be held and you and we do not
              agree to the locale where the hearing is to be held, the
              [ARBITRATION BODY] initially will determine the locale of the
              arbitration. If a party does not agree with the [ARBITRATION
              BODY]’s decision, that party can ask the arbitrator, once
              appointed, to make a final determination. <br />
              <br />
              Disputes may be resolved by submission of documents alone without
              in-person or telephonic hearings. For cases being decided by the
              submission of documents only, the [ARBITRATION BODY]’s rules for
              document-only arbitrations shall apply. Arbitrations will be
              document-only where no disclosed claims or counterclaims exceed
              $[AMOUNT], unless any party requests an in-person or telephonic
              hearing or the arbitrator decides that a hearing is necessary.
              <br />
              <br /> Any dispute regarding arbitrability of the Dispute or of
              this agreement to arbitrate shall be decided by the arbitrator.{" "}
              <br />
              d. Payment of all filing, case-management, administrative,
              hearing, and arbitrator fees will be governed by the [ARBITRATION
              BODY]’s rules and fee schedules[, unless otherwise stated in these
              Terms of Service]. <br />
              e. The arbitrator shall base their decision on these Terms, as
              interpreted under the governing law set forth in Section IV. The
              decision of the arbitrator shall be final and binding, and
              judgment upon the award rendered by the arbitrator may be entered
              by any court of competent jurisdiction. The decision of the
              arbitrator shall be in the form of a written decision rendered
              within [thirty (30)] days after the conclusion of the arbitration
              hearing, such written decision to include the findings of fact and
              conclusions of law upon which it is based. The arbitrator shall be
              empowered to grant any award in law or equity including, but not
              limited to, monetary damages (which shall be limited to
              compensatory damages only), injunctive relief, including temporary
              restraining orders prior to rendering a final judgment, and
              reasonable attorneys’ fees. <br />
              f. [Other than as provided below,] no arbitration or litigation
              shall be commenced between you and Company prior to or during the
              Negotiation Period, except for equitable remedies such as
              injunctive relief or specific performance. <br />
              g. The agreement to arbitrate contained in this Section IX shall
              continue in full force and effect despite the expiration or
              termination of this agreement. <br />
              <br />
              C. Exceptions From Arbitration You and Company each retain the
              rights to: <br />
              a) Have any claims resolved in small claims court on an individual
              basis within the scope of such court's jurisdiction; or
              <br />
              b) Bring an action in state or federal court to protect
              intellectual property rights such as copyrights, patents, moral
              rights, trademarks, trade secrets, and other confidential or
              proprietary information, but not privacy or publicity rights.{" "}
              <br />
              <br />
              D. You and Company agree that, to the extent allowed by law,
              claims may only be brought against the other on an individual
              basis, and not on a class, representative, or collective basis.
              Each party may only seek or obtain individualized relief, and
              except as provided in “Batch Arbitration” below, disputes between
              the parties cannot be arbitrated or consolidated with those of any
              other person. <br />
              <br />
              E. Batch Arbitration <br />
              1. In the event that, in a ninety-day period, 25 or more similar
              arbitration demands (demands with the same or substantially
              similar facts or claims and seeking the same or substantially
              similar relief) are submitted by parties represented by the same
              or coordinated counsel, the disputes must be arbitrated in batches
              of up to [NUMBER] claimants each. <br />
              2. Upon notice of either party, the [ARBITRATION BODY] shall group
              the claimants into batches of [NUMBER] size, with a smaller final
              batch of remaining claimants if the number of claimants is greater
              than [NUMBER]. All the claims in each batch shall be resolved as a
              single consolidated arbitration with a single arbitrator appointed
              by [ARBITRATION BODY], one set of arbitration fees, and one
              hearing (if any) per batch, and one final award, provided the
              parties agree to cooperate in good faith to establish a process to
              establish individual issues.
            </p>

            <h5>XII. DISCLAIMER OF WARRANTIES</h5>
            <p>
              A. YOU EXPRESSLY AGREE THAT USE OF THE SERVICE IS AT YOUR SOLE
              RISK. BOTH THE SITE AND THE SERVICES ARE PROVIDED ON AN “AS IS”
              AND “AS AVAILABLE” BASIS. THE COMPANY DISCLAIMS ALL WARRANTIES OF
              ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
              TO, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              USE OR PURPOSE, NON-INFRINGEMENT, TITLE, OPERABILITY, CONDITION,
              QUIET ENJOYMENT, VALUE, ACCURACY OF DATA, AND SYSTEM INTEGRATION.
              <br />
              <br />
              B. The Company is not responsible for any error, omission,
              interruption, deletion, defect, delay in operation or
              transmission, communications line failure, theft or destruction,
              or unauthorized access to, or alteration of, any Communication
              caused by the user, the Company, or a third-party. The Company is
              not responsible for any problems or technical malfunctions of
              network or phone lines, computer equipment or systems, servers,
              providers, software, email providers, or internet traffic,
              including injury or damage to you or any other person's computer
              related to or resulting from using our Site or the Services. Under
              no circumstances will the Company or any of its affiliates or
              third parties be responsible for any loss or damage, including
              personal injury or death, resulting from anyone's use of the Site
              or the Services, or any content posted on or transmitted from the
              Site or relayed through the Services provided. The Company does
              not guarantee or promise any specific results from your use of the
              Site or the Services.
            </p>

            <h5>XIII. LIMITATIONS ON LIABILITY</h5>
            <p>
              A. THE SERVICE IS MADE AVAILABLE TO YOU “AS IS” AND “AS
              AVAILABLE.”
              <br />
              <br />
              B. TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL
              THE COMPANY OR ANY OF ITS OFFICERS, EMPLOYEES, PARENTS,
              SUBSIDIARIES, AFFILIATES, AGENTS, OR LICENSORS BE LIABLE FOR ANY
              CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, ACTUAL, PUNITIVE,
              AND/OR EXEMPLARY DAMAGES (INCLUDING BUT NOT LIMITED TO LOSS OF
              PROFITS, REVENUES, GOODWILL, USE OR DATA, SERVICE INTERRUPTION,
              COMPUTER DAMAGE OR SYSTEM FAILURE, COST OF SUBSTITUTE PRODUCTS OR
              SERVICES, BODILY INJURY, OR DEATH OR OTHER INTANGIBLE LOSSES)
              ARISING OUT OF OR RELATED IN ANY WAY TO YOUR USE OF THE SITE OR
              THE SERVICES, REGARDLESS OF WHETHER SUCH DAMAGES ARE UNDER THEORY
              OF BREACH OF CONTRACT, TORT, NEGLIGENCE, CONTRACT, WARRANTY,
              STATUTE, STRICT LIABILITY, OR OTHERWISE, EVEN IF WE OR THEY HAVE
              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING
              ANYTHING CONTRARY CONTAINED HEREIN, THE COMPANY'S LIABILITY TO YOU
              ARISING FROM THESE TERMS, THE USE OF OR INABILITY TO USE THE SITE
              OR THE SERVICES, WILL AT ALL TIMES BE LIMITED TO THE GREATER OF
              $[LIMITATION] USD OR THE AMOUNT PAID BY YOU TO THE COMPANY FOR
              ACCESS TO AND USE OF THE SITE AND/OR THE SERVICES.
              <br />
              <br />
              C. YOU RELEASE THE COMPANY AND HOLD IT AND ITS THIRD-PARTY
              TECHNOLOGY PLATFORM PROVIDER, SUPPLIERS, AND PROVIDERS, AND ANY
              THIRD-PARTY WHO PROMOTES THE SERVICE OR PROVIDES YOU WITH A LINK
              TO THE WEBSITE HARMLESS FROM ANY AND ALL CLAIMS, DEMANDS, AND
              DAMAGES OF EVERY KIND AND NATURE AS CITED ABOVE, ARISING OUT OF OR
              IN ANY WAY CONNECTED WITH YOUR USE OF THE SERVICE. YOU WAIVE THE
              PROVISION OF ANY STATE OR LOCAL LAW LIMITING OR PROHIBITING A
              GENERAL RELEASE.
              <br />
              <br />
              D. To the extent the Company may not, as a matter of applicable
              law, disclaim any implied warranty or limit its liabilities, the
              scope and duration of such warranty and the extent of our
              liability will be the minimum permitted under such applicable law.
            </p>

            <h5>XIV. INDEMNIFICATION</h5>
            <p>
              YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD THE COMPANY, ITS
              AFFILIATES, ITS THIRD-PARTY TECHNOLOGY PLATFORM PROVIDER AND ANY
              SUBSIDIARIES, AFFILIATES, OFFICERS, AGENTS, AND OTHER THIRD-PARTY
              PARTNERS AND EMPLOYEES, HARMLESS FROM ANY AND ALL CLAIMS, DAMAGES,
              LOSSES, LIABILITIES, DEMANDS, COSTS, AND EXPENSES OF EVERY KIND
              AND NATURE (INCLUDING ACTUAL, SPECIAL, INCIDENTAL, AND
              CONSEQUENTIAL), KNOWN OR UNKNOWN, SUSPECTED OR UNSUSPECTED,
              DISCLOSED OR UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED
              TO, YOUR USE OF THE SITE AND THE SERVICES. YOU WAIVE THE PROVISION
              OF ANY STATE OR LOCAL LAWS THAT LIMIT OR PROHIBIT SUCH A GENERAL
              RELEASE.
            </p>

            <h5>XV. ADDITIONAL TERMS</h5>
            <p>
              A. The Terms contain the entire agreement between you and Us
              regarding the use of the Service, and supersede any prior
              agreement between you and us on such subject matter. The parties
              acknowledge that no reliance is placed on any representation made
              but not expressly contained in these Terms.
              <br />
              <br />
              B. The failure of Company to enforce any right or provision of
              these Terms of Service does not constitute a waiver of future
              enforcement of any right or provision. If, for any reason, a court
              of competent jurisdiction or an arbitrator finds any portion of a
              provision of these Terms of Service unenforceable or invalid, that
              provision will otherwise be enforced to the maximum extent
              permissible. All other provisions of these Terms of Service will
              remain in full force and effect.
              <br />
              <br />
              C. If any provision of the Terms is found to be unenforceable or
              invalid by an arbitrator or court of competent jurisdiction, then
              only that provision shall be modified to reflect the parties’
              intention or eliminated to the minimum extent necessary so that
              the Terms shall otherwise remain in full force and effect and
              enforceable.
              <br />
              <br />
              D. The Terms, and any rights or obligations under them, are not
              assignable, transferable or sublicensable by you except with
              Company's prior written consent, but may be assigned or
              transferred by us without restriction. Any attempted assignment by
              you shall violate these Terms and be void.
              <br />
              <br />
              E. You agree that no joint venture, partnership, employment,
              agency, special or fiduciary relationship exists between you and
              Company as a result of these Terms or your use of the Service.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
