import { forwardRef } from "react";

const ContactSales = forwardRef((props, ref) => {
  return (
    <div
      ref={ref}
      id={props?.id}
      className="text-white bg-[#5479F7] text-center px-4 md:px-0 md:mx-20 py-20 my-16"
    >
      <h1 className="text-4xl md:text-[56px] max-w-[793px] mx-auto mb-4 leading-10 md:leading-[67px]">
        Unlock the full potential of your business with Ae'lkimi!
      </h1>
      <button className="bg-[#7DF9FF] rounded py-4 px-6 text-[#5479F7] font-semibold" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        Contact Us
      </button>
    </div>
  );
});

export default ContactSales;
