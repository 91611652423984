
import aeLogo from "../../logo.svg";
import linkedinLogo from "../../assets/images/linkedin-logo.webp";

const Footer = () => {
  return (
    <footer className="bg-[#08192C] text-white px-5 lg:px-10 py-16">
      <div className="flex justify-between flex-col lg:flex-row">
        <div className="hidden lg:flex flex-col">
          <img src={aeLogo} alt="aelkimi" className="w-60 mb-3" />
          <p className="text-3xl text-white">
            HR meets IPA: one byte at a time.
          </p>
        </div>
        <div className="flex flex-col gap-5">
          {/* <h5 className="text-2xl">Resources</h5>
          <a href="/privacy-policy" className="text-base">
            Privacy Policy
          </a>
          <a href="/terms-of-service">Terms of Service</a> */}
        </div>
        <div className="flex flex-col gap-5 mt-10 lg:mt-0">
          <h5 className="text-2xl mb-2.5">Connect with us</h5>
          <a
            href="https://www.linkedin.com/in/leongcheah/"
            target="_blank"
            rel="noreferrer"
            className="mb-2.5"
          >
            <img
              src={linkedinLogo}
              alt="linkedin"
              className="w-9 h-9 object-cover"
            />
          </a>
          <a href="mailto:leong.cheah@aelkimi.com" target="_blank" rel="noreferrer">leong.cheah@aelkimi.com</a>
        </div>
      </div>
      <p className="lg:text-center w-full mt-10">
        © {new Date().getFullYear()} by Aelkimi. All Rights Reserved
      </p>
    </footer>
  );
};

export default Footer;
