import { useEffect, useState } from "react";
import Home from "./pages/Home";
import "./App.css";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  const navigate = (path) => {
    window.history.pushState({}, "", path);
    setCurrentPath(path);
  };

  useEffect(() => {
    const handlePopState = () => {
      setCurrentPath(window.location.pathname);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const renderContent = () => {
    switch (currentPath) {
      case "/privacy-policy":
        return <PrivacyPolicy navigate={navigate} />;
      case "/terms-of-service":
        return <TermsOfService navigate={navigate} />;
      case "/":
      default:
        return <Home navigate={navigate} path={currentPath} />;
    }
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} theme="colored" />
      <div className="app-container">
        <Header />
        <main>{renderContent()}</main>
        <Footer />
      </div>
    </>
  );
}

export default App;
